<template>
<div id="publish">
  <div class="addCompany-ct">
    <div class="attestion">
      <h3>{{title}}</h3>
    </div>
    <div class="publishCompany">
      <el-form :inline="true" :model="publishModel" :rules="rules" ref="ruleForm" label-width="110px">
        <el-form-item label="职位名称" prop="positionName">
         <el-input  placeholder="请输入职位名称" v-model="publishModel.positionName" @focus="openPosition"></el-input>
        </el-form-item>
        <el-form-item label="工作经验" prop="workExperience">
          <el-select v-model="publishModel.workExperience" placeholder="请选择工作经验">
            <el-option v-for="item in experienceList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招聘类型" prop="tecruitmentType">
          <el-select v-model="publishModel.tecruitmentType" placeholder="请选择招聘类型">
            <el-option v-for="item in typeList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最低学历" prop="minimumEducation">
          <el-select v-model="publishModel.minimumEducation" clearable placeholder="请选择最低学历">
            <el-option v-for="item in backgroundList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最低年龄要求" prop="ageMinimum">
          <el-input v-model="publishModel.ageMinimum" clearable placeholder="请输入年龄要求"></el-input>
        </el-form-item>
        <el-form-item label="最高年龄要求" prop="ageRequirements">
          <el-input v-model="publishModel.ageRequirements" clearable placeholder="请输入年龄要求"></el-input>
        </el-form-item>
        <el-form-item label="薪资范围" prop="minimumWage">
          <el-select clearable @change="selectSalary" v-model="publishModel.minimumWage" placeholder="请选择最低薪资">
            <el-option v-for="item in salaryList" :key="item.salary" :label="item.salary" :value="item.salary">
            </el-option>
          </el-select>
          <span class="until">至</span>
          <el-select clearable v-model="publishModel.maximumSalary" placeholder="请选择最高薪资">
            <el-option v-for="item in maxSalaryList" :key="item.salary" :label="item.salary" :value="item.salary">
            </el-option>
          </el-select>
          <span class="until">元</span>
        </el-form-item>
        <el-form-item label="招聘人数" prop="recruitingNumbers">
          <el-input v-model="publishModel.recruitingNumbers" clearable placeholder="请输入招聘人数"></el-input>
        </el-form-item>
        <el-form-item label="性别要求" prop="genderRequirements">
          <el-select v-model="publishModel.genderRequirements" clearable placeholder="请选择性别要求">
            <el-option v-for="item in sexList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="薪资福利" prop="selectBenefits">
          <el-select multiple  v-model="publishModel.selectBenefits" placeholder="请选择薪资福利">
            <el-option v-for="item in benefitsList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作地址" prop="workAddressId">
          <el-select v-model="publishModel.workAddressId" @change="selectAddress" clearable placeholder="请选择工作地址">
            <el-option v-for="item in workAddressList" :key="item.id" :label="item.workAddress+item.houseNumber" :value="item.id">
            </el-option>
          </el-select>
          <span class="addAddress" @click="dialogWork=true">添加地址</span>
        </el-form-item>
        <el-form-item label="招聘描述" prop="jobDescription">
          <el-input type="textarea" v-model="publishModel.jobDescription" placeholder="请输入招聘描述"></el-input>
        </el-form-item>

        <div class="saveBtn" @click="publish">{{pubText}}</div>
      </el-form>
    </div>
    <!-- 添加地址 -->
    <el-dialog title="添加工作地址" :visible.sync="dialogWork" width="700px">
      <div class="addWorkAddress publishCompany">
        <el-form :inline="true" :model="addressModel" ref="workForm" label-width="150px">
          <el-form-item label="工作地点" prop="workAddress">
            <el-input @focus="openMap" v-model="addressModel.workAddress" placeholder="请选择工作地点"></el-input>
          </el-form-item>
          <el-form-item label="楼层/单元室/门牌号" prop="houseNumber">
            <el-input v-model="addressModel.houseNumber" clearable placeholder="请输入楼层/单元室/门牌号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogWork = false">取 消</el-button>
        <el-button type="primary" @click="saveAddress">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 地图 -->
    <div class="mapBox">
      <el-dialog title="选择工作地点" :visible.sync="dialogMap" width="700px">
        <div class="workMap">
          <el-amap-search-box  class="search-box"  :search-option="searchOption"  :on-search-result="onSearchResult">
          </el-amap-search-box>

          <el-amap ref="map"  vid="amapDemo" :events="events" zoom="18" :center="center"  class="amap-demo">
            <el-amap-marker key="100" :position="center"></el-amap-marker>
          </el-amap>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogMap = false">取 消</el-button>
          <el-button type="primary" @click="dialogMap = false" >确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 选择职位 -->
    <div class="positionBox">
      <el-dialog title="职位名称" :visible.sync="showPosition" width="700px">
        <div class="positionName">
            <el-form :inline="true" label-width="80px">
              <el-form-item label="职位名称">
                <el-input @input="changePosition" v-model="positionThreeName" placeholder="请输入职位名称" ></el-input>
              </el-form-item>
            </el-form>
        </div>
        <div class="classify" v-show="!showSearch">
          <div class="classify-title">
            <span v-show="positionOneName">{{positionOneName}}<i class="el-icon-arrow-right"></i></span>
            <span v-show="positionTwoName">{{positionTwoName}}<i class="el-icon-arrow-right"></i></span>
            <span v-show="positionThreeName">{{positionThreeName}}<i class="el-icon-arrow-right"></i></span>
            <label v-show="grade==1">请选择一级分类</label>
            <label v-show="grade==2">请选择二级分类</label>
            <label v-show="grade==3">请选择三级分类</label>
          </div>
          <div class="positionCt" v-show="grade==1">
            <div class="positionItem" @click="selectOne(item)" v-for="(item,index) in positionList" :key="index">
              {{item.name}}
            </div>
          </div>
          <div class="positionCt" v-show="grade==2">
            <div class="positionItem" @click="selectTwo(item)" v-for="(item,index) in positionList" :key="index">
              {{item.name}}
            </div>
          </div>
          <div class="positionCt" v-show="grade==3">
            <div class="positionItem" @click="selectThree(item)" v-for="(item,index) in positionList" :key="index">
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="positionCt searchPosition" v-show="showSearch">
          <div class="positionItem" @click="selectPosition(item)" v-for="(item,index) in positionList" :key="index">
            {{item.name}}
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showPosition = false">取 消</el-button>
          <el-button type="primary" @click="surePosition" >确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</div>
</template>

<script>
import {getSubordinateGradePosition,getSeniorGradePosition, postPositionInfo, getJobDetails, getDictData, getSalaryRequirements, getWorkAddressList, addWorkAddress } from "@/api/index.js";
export default {
  name: "addContacts",
  data() {
    let _this = this;
    return {
      title: '职位基本信息',
      pubText:'发布职位',
      publishModel: {
        id:'',
        positionCode: '',
        positionName:'',
        workExperience: '',
        ageMinimum:'',
        ageRequirements: '',
        genderRequirements: '',
        recruitingNumbers: '',
        minimumEducation:'',
        minimumWage: '',
        maximumSalary: '',
        workAddressId: '',
        workAddressName: '',
        jobDescription:'',
        tecruitmentType:'',
        materialBenefits:'',
        selectBenefits:['1','2']
      },
      dialogWork: false,
      rules: {
        positionName: [
          { required: true, message: '请选择职位名称', trigger: 'change' }
        ],
        workAddressId: [
          { required: true, message: '请选择工作地址', trigger: 'change' }
        ],
        minimumWage: [
          { required: true, message: '请选择薪资', trigger: 'change' }
        ],
        workExperience: [
          { required: true, message: '请选择工作经验', trigger: 'change' }
        ],
        jobDescription: [
          { required: true, message: '请输入招聘描述', trigger: 'blur' }
        ],
        tecruitmentType: [
          { required: true, message: '请选择招聘类型', trigger: 'change' }
        ],
         minimumEducation: [
          { required: true, message: '请选择最低学历', trigger: 'change' }
        ]
      },
      addressModel: {
        houseNumber: '',
        workAddress: ''
      },
      experienceList: [],
      statusList: [],
      typeList: [],
      sexList: [],
      salaryList: [],
      maxSalaryList: [],
      backgroundList: [],
      workAddressList: [],
      jobInfo: {},
      dialogMap:false,
      center: [93.51452,42.82699],
      events: {
        init(){
          // _this.getRecruiterEnterpriseDetails()
        },
        click(e) {
           _this.center = [e.lnglat.lng, e.lnglat.lat];
          _this.getFormattedAddress();

        }
      },
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false,
      },
      showPosition:false,
      positionList:[],
      positionOneName:'',
      positionTwoName:'',
      positionThreeName:'',
      grade:1,
      showSearch:false,
      benefitsList:[]
    }
  },
  mounted() {
    this.getSalaryRequirements()
    this.getWorkAddressList()
    this.getDictData('position_status')
    this.getDictData('tecruitment_type')
    this.getDictData('work_experience')
    this.getDictData('sys_user_sex')
    this.getDictData('background_type')
    this.getDictData('company_benefits')
    this.positionId = this.$route.query.positionId
    if (this.positionId) {
      this.title = '编辑职位信息'
      this.pubText = '保存'
      this.getJobDetails()
    }
  },
  methods: {
    selectPosition(item){
      this.positionThreeName = item.name
      this.publishModel.positionCode = item.code
    },
    surePosition(){
      this.publishModel.positionName =  this.positionThreeName 
      if(!this.publishModel.positionCode){
        this.publishModel.positionCode =  this.positionThreeName 
      }
      this.showPosition = false
    },
    changePosition(val){
      this.positionThreeName = val
      this.grade = 3
      this.positionList = []
      this.showSearch = true
      this.publishModel.positionCode = ''
      this.getSubordinateGradePosition()
    },
    getSeniorGradePosition(){
       getSeniorGradePosition().then(res=>{
         this.positionList=res.rows
       })
    },
    getSubordinateGradePosition(){
      getSubordinateGradePosition({
        grade:this.grade,
        positionCode:this.publishModel.positionCode,
        name:this.positionThreeName
      }).then(res=>{
         this.positionList=res.rows
      })
    },
    selectOne(item){
      this.positionOneName = item.name
      this.grade = 2
      this.publishModel.positionCode = item.code
      this.positionList = []
      this.getSubordinateGradePosition()
    },
    selectTwo(item){
      this.positionTwoName = item.name
      this.grade = 3
      this.publishModel.positionCode = item.code
      this.positionList = []
      this.getSubordinateGradePosition()
    },
    selectThree(item){
      this.positionThreeName = item.name
      this.publishModel.positionCode = item.code
    },
    openPosition(){
       this.showPosition = true
      this.positionOneName = ''
      this.positionTwoName = ''
       this.positionThreeName = ''
       this.grade = 1
       this.positionList = []
       this.showSearch = false
       this.getSeniorGradePosition()
       this.publishModel.positionCode = ''
    },
    getFormattedAddress() {
      window.AMap.plugin('AMap.Geocoder', () => {
        let GeocoderOptions = {
            city: '全国'
        };
        let geocoder = new window.AMap.Geocoder(GeocoderOptions);
        geocoder.getAddress(this.center, (status, result) => {
            console.log('通过经纬度拿到的地址', result);
            if (status === 'complete' && result.info === 'OK') {
              this.addressModel.workAddress = result.regeocode.formattedAddress;
            } 
        });
      });
    },
    onSearchResult(pois) {
      console.log(pois)
      if (pois && pois.length > 0) {
        //如果长度为1则无需转化
        let poi = pois[0];
        let lng = poi["lng"];
        let lat = poi["lat"];
        this.center = [lng, lat];
      }
    },
    openMap(){
      this.dialogMap = true
    },
    getJobDetails() {
      getJobDetails({
        positionInfoId: this.positionId
      }).then(res => {
        this.jobInfo = res.data
        this.publishModel.positionCode = this.jobInfo.positionCode
        this.publishModel.positionName = this.jobInfo.positionName
        this.publishModel.workExperience = this.jobInfo.workExperience
        this.publishModel.ageRequirements = this.jobInfo.ageRequirements
        this.publishModel.ageMinimum = this.jobInfo.ageMinimum
        this.publishModel.genderRequirements = this.jobInfo.genderRequirements
        this.publishModel.recruitingNumbers = this.jobInfo.recruitingNumbers
        this.publishModel.minimumWage = this.jobInfo.minimumWage
        this.publishModel.maximumSalary = this.jobInfo.maximumSalary
        this.publishModel.workAddressId = this.jobInfo.workAddressId
        this.publishModel.jobDescription = this.jobInfo.jobDescription
        this.publishModel.tecruitmentType = String(this.jobInfo.tecruitmentType)
        this.publishModel.minimumEducation = this.jobInfo.minimumEducation
        this.publishModel.id = this.jobInfo.id
        this.publishModel.materialBenefits = this.jobInfo.materialBenefits
    
        if(this.jobInfo.materialBenefits){
          this.publishModel.selectBenefits = this.jobInfo.materialBenefits.split(',')
        }else{
           this.publishModel.selectBenefits = []
        }

      })
    },
  
    saveAddress() {
      addWorkAddress({
        houseNumber: this.addressModel.houseNumber,
        workAddress: this.addressModel.workAddress,
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getWorkAddressList()
          this.dialogWork = false
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
        console.log(res)

      })
    },
    getSalaryRequirements() {
      getSalaryRequirements().then(res => {
        this.salaryList = res.rows
      })
    },
    getWorkAddressList() {
      getWorkAddressList().then(res => {
        this.workAddressList = res.rows
        console.log(res)
      })
    },
    selectSalary(val) {
      this.minSalaryList = []
      console.log(val)
      if(val=='面议'){
        return
      }
      this.publishModel.maximumSalary = ''
      this.salaryList.forEach(s => {
        if (s.salary == val) {
          this.maxSalaryList = s.salaryList
        }
      })

    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'work_experience') {
          this.experienceList = res.rows
        }
        if (dictType == 'position_status') {
          this.statusList = res.rows
        }
        if (dictType == 'tecruitment_type') {
          this.typeList = res.rows
        }
        if (dictType == 'sys_user_sex') {
          this.sexList = res.rows
        }
        if (dictType == 'background_type') {
          this.backgroundList = res.rows
        }
        if (dictType == 'company_benefits') {
          this.benefitsList = res.rows
        }
      })
    },

    selectAddress(e) {
      this.workAddressList.forEach(s => {
        if (s.id == e) {
          this.publishModel.workAddressName = s.workAddress + s.houseNumber
          console.log(this.publishModel.workAddressName)
        }
      });
    },
    publish() {
      this.publishModel.materialBenefits = this.publishModel.selectBenefits.join(',')
    
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          postPositionInfo(this.publishModel).then(res => {
            if (res.code == 200) {
              this.$message({
                message: '成功',
                type: 'success'
              })
              this.$router.push({
                path: '/mine'
              })
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
        }
      });

    }
  }
};
</script>

<style lang="scss" scoped>
#publish {
  width: 100%;
  min-height: 814px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  padding-bottom: 20px;
  padding-top: 24px;
}
.positionCt{
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  height: 400px;
  overflow:auto;
  .positionItem{
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: #444;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  .positionItem:hover{
    color:#0077FF
  }
}
.searchPosition{
  margin-top: 0;
}
.addCompany-ct {
  width: 880px;
  margin: 0 auto;
  min-height: 430px;
  background: #FFFFFF;
  padding: 42px 100px 41px 100px;
  border-radius: 1px 10px 10px 10px;

  .until {
    margin: 0 12px;
  }

  .attestion {
    text-align: center;

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }

    p {
      font-size: 16px;
      color: #222222;
      margin-top: 26px;
      margin-bottom: 20px;
    }
  }

  .publishCompany {
    padding: 0;
    background: none;
    margin-top: 30px;
  }
}
.searchPosition{

}
.addAddress {
  margin-left: 30px;
  font-weight: 500;
  color: #0077FF;
  font-size: 18px;
  cursor: pointer;
}
.workMap{
  overflow: hidden;
  .amap-demo{
    height:280px;
  }
  .el-vue-search-box-container {
   width: 644px;
   margin-left: 0;
   margin-bottom: 10px;
   border: 1px solid #eee;
   padding-right: 10px;
  }
}
.saveBtn {
  text-align: center;
  width: 265px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background: #0077FF;
  border-radius: 5px;
  margin-left:300px;
  cursor: pointer;
}
</style><style lang="scss">
.avator-item {
  .el-form-item__label {
    margin-top: 13px;
  }
}
.mapBox{
  .el-dialog{
    height: 480px;
  }
  .el-dialog__body{
    padding:10px 20px;
  }
}
.positionBox{
  .el-dialog__body{
    padding:10px 30px;
  }
}
.publishCompany {
  .el-form-item {
    margin-bottom: 40px;
  }

  .el-form-item__label {
    font-size: 16px;
    color: #000000;
  }

  .el-input__inner {
    width: 320px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }

  .el-textarea__inner {
    width: 750px;
    min-height: 140px !important;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }
}
.positionName{
  .el-input__inner {
     width: 540px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }
  .el-form-item__label {
    font-size: 16px;
    color: #000000;
  }
}
.addWorkAddress{
  .el-input__inner {
    width: 490px;
    font-size: 14px;
  }

}
</style>
